import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link } from "gatsby"
import AboutSidebar from "../../components/layout/about-sidebar"

const PressReleasesPage = () => {
  return (
    <Layout>
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/press-releases">Press Releases</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={9}>
            <h2 className="generalheader">Press Releases</h2>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default PressReleasesPage
